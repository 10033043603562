import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '@app/pages/dashboard';
import { AuthLayout } from '@app/components/layout';
import { Request } from '@app/pages/request';
import { useAuth } from '@app/auth/auth-context';

export function AppRouter() {
  const { authenticated } = useAuth();

  return (
    <AuthLayout>
      <Routes>
        <Route path="/" index element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/" />} />

        {authenticated && <Route path="/request/:id" element={<Request />} />}
      </Routes>
    </AuthLayout>
  );
}
