import axios from 'axios';
import {toast} from 'react-toastify';

import {Env} from '@app/config/env';
import {ResponseStatusCodes} from '@app/config/api/response-status-codes';
import {AuthService} from '@app/auth/auth-service';
import {Logger} from "@app/utils/logger/logger-service";

const instance = axios.create({
  baseURL: Env.get('BACKEND_APP_API_URL'),
});

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${AuthService.getAuthToken()}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.message) {
      // FIXME: Consider to use common Store instead of direct call to `toast`
      //toast.error(error.response?.data?.message);
      Logger.error(error)
      return;
    }

    //toast.error('Request error');
  },
);

/* Authorize error */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === ResponseStatusCodes.AUTH_ERROR)
      AuthService.signOutUser()
    return Promise.reject(error);
  },
);

const axiosInstance = instance;
export default axiosInstance;
