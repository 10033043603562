import React, { forwardRef, useContext, useEffect, useState, useRef, useImperativeHandle } from 'react';
import { AutoField, AutoForm, SubmitField } from 'uniforms-antd';
import { Checkbox, Col, Row, Space } from 'antd';
import { toast } from 'react-toastify';
import { connectField } from 'uniforms';

import { initializeSchema } from '@app/schema';

import { LanguageContext } from '@app/global_provider';
import { FileUploaderField } from '@app/components/file_uploader/file-uploader-field';
import { FilesRepresentor } from '@app/components/file_uploader/files-representor';
import { i18n } from '@app/translations/i18n.config';
import { generateId } from '@app/utils/identifiers/identifiers';
import { Tools } from '@app/utils/tools';

interface DynamicFormProps {
  template: any;
  hideModal?: () => void;
  onSubmit?: (data: any) => void;
  isPersisted?: boolean;
  receiptValues?: any;
}

const UploadFilesField = connectField(FileUploaderField);
const RepresentFilesField = connectField(FilesRepresentor);

export const DynamicForm = forwardRef(
  ({ template, hideModal, onSubmit, isPersisted, receiptValues }: DynamicFormProps, ref) => {
    const { isRtl } = useContext<any>(LanguageContext);
    const formRef = useRef(null);

    const [schema, setSchema] = useState<any>(null);
    const [isAdditional, setIsAdditional] = useState<boolean>(true);

    useEffect(() => {
      if (template) {
        try {
          const bridge = initializeSchema(template);
          setSchema(bridge);
        } catch (error) {
          toast.error(i18n.t('messages.invalidTemplate'));
        }
      }
    }, [template]);

    const handleSubmitForm = async (model: any) => {
      receiptValues ? onSubmit(model) : onSubmit({ id: generateId(), ...model });

      formRef.current.reset();

      if (!isAdditional || Tools.isPresent(receiptValues)) {
        hideModal();
      }
    };

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        if (formRef.current) {
          formRef.current.reset();
        }
      },
    }));

    const renderFormFields = (properties: any) => {
      const ordering = schema?.schema?.uniforms?.ui?.order || [];
      return Object.keys(properties)
        .sort((a, b) => ordering.indexOf(a) - ordering.indexOf(b))
        .map((key) => {
          const property = properties[key];
          const propertyType = property?.uniforms?.type || property?.type;
          const fieldName = property.description || key;

          switch (propertyType) {
            case 'file':
              const FieldComponent = isPersisted ? RepresentFilesField : UploadFilesField;
              return <FieldComponent key={key} name={key} label={fieldName} />;

            default:
              return <AutoField min={0} key={key} name={key} label={fieldName} />;
          }
        });
    };

    if (!schema) {
      return null;
    }

    return (
      <Row>
        <Col className={`p-3 ${isRtl && 'form-right-align'}`} span={24}>
          {receiptValues ? (
            <AutoForm
              placeholder
              validate="onChangeAfterSubmit"
              model={receiptValues}
              schema={schema}
              onSubmit={handleSubmitForm}
              ref={formRef}
            >
              {renderFormFields(schema?.schema?.properties)}

              <Space className={'w-100 justify-content-between mt-5'}>
                <SubmitField value={i18n.t('invoices.edit')} onSubmit={handleSubmitForm} disabled={false} />
              </Space>
            </AutoForm>
          ) : (
            <AutoForm
              placeholder
              validate="onChangeAfterSubmit"
              schema={schema}
              onSubmit={handleSubmitForm}
              ref={formRef}
            >
              {renderFormFields(schema?.schema?.properties)}

              <Space className={'w-100 justify-content-between mt-5'}>
                <Checkbox defaultChecked checked={isAdditional} onChange={(e) => setIsAdditional(e.target.checked)}>
                  {i18n.t('invoices.additionalInvoiceText')}
                </Checkbox>
                <SubmitField value={i18n.t('invoices.add')} onSubmit={handleSubmitForm} disabled={false} />
              </Space>
            </AutoForm>
          )}
        </Col>
      </Row>
    );
  },
);
