import {Empty, Spin} from 'antd';
import React, {JSX} from 'react';

export function RQContent({status, children, emptyElement}: RQContentProps) {
  const getContent = () => (children instanceof Function ? children() : children);
  const showEmpty = () => status === 'error' || (Array.isArray(status) && status.some((x) => x === 'error'));
  const showLoading = () => status === 'loading' || (Array.isArray(status) && status.some((x) => x === 'loading'));

  if (showEmpty())
    return (
      <div className="d-flex flex-row justify-content-center w-100 py-3">
        {emptyElement ? (
          emptyElement
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT}/>
        )}
      </div>
    );
  if (showLoading())
    return (
      <div className="d-flex flex-row justify-content-center align-items-center w-100 py-3">
        <Spin size="large"/>
      </div>
    );
  return getContent();
}

type RQStatus = 'idle' | 'error' | 'loading' | 'success';

interface RQContentProps {
  status: RQStatus[] | RQStatus;
  children: (() => JSX.Element) | JSX.Element;
  emptyElement?: JSX.Element;
}
