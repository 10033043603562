import React, { useCallback, useEffect, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';

import { AuthUser } from '@app/types/auth-user';
import { AuthContext, IAuthContext } from '@app/auth/auth-context';
import { AuthService } from '@app/auth/auth-service';
import { Space, Spin } from 'antd';
import { Logger } from '@app/utils/logger/logger-service';

export const AuthProvider = ({ children }: any) => {
  const { keycloak, initialized } = useKeycloak();

  const [userInfo, setUserInfo] = useState({} as AuthUser);
  const [userRoles, setUserRoles] = useState([] as string[]);
  const [authToken, setAuthToken] = useState(AuthService.getAuthToken());
  const [idToken, setIdToken] = useState(AuthService.getIdToken());
  const [refreshToken, setRefreshToken] = useState(AuthService.getRefreshToken());

  const handleLogout = async () => {
    try {
      setUserInfo({} as AuthUser);
      setUserRoles([] as string[]);
      setAuthToken('');
      setRefreshToken('');

      AuthService.signOutUser();
      await keycloak.logout();
    } catch (e) {
      Logger.error(e);
    }
  };

  const handleLogin = async () => {
    const userInfo = await keycloak.loadUserInfo();
    Logger.info('Authorized user: ', userInfo);

    const roles = keycloak?.tokenParsed?.resource_access[keycloak?.clientId]?.roles;

    AuthService.signInUser(keycloak?.token, keycloak?.refreshToken, keycloak?.idToken);

    setUserInfo(userInfo as AuthUser);
    setUserRoles(roles);
    setAuthToken(keycloak?.token);
    setRefreshToken(keycloak?.refreshToken);
    setIdToken(keycloak?.idToken);
  };

  const fetchAuthUserData = useCallback(async () => {
    if (initialized && keycloak.authenticated) {
      await handleLogin();
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    fetchAuthUserData();
  }, [initialized, keycloak, fetchAuthUserData]);

  if (!initialized) {
    // FIXME:
    return (
      <Space className={'w-100 vh-100 justify-content-center'}>
        <Spin size={'large'} tip={'Loading...'} />
      </Space>
    );
  }

  if (!keycloak.authenticated) {
    // FIXME:
    return (
      <Space className={'w-100 vh-100 justify-content-center'}>
        <Spin size={'large'} tip={'Redirecting to login...'} />
      </Space>
    );
  }

  const authContextValues = {
    user: userInfo,
    authenticated: keycloak.authenticated,
    client: keycloak,
    userRoles,
    idToken,
    authToken,
    refreshToken,
    onLogout: handleLogout,
  } as IAuthContext;

  return <AuthContext.Provider value={authContextValues}>{children}</AuthContext.Provider>;
};
