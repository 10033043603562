import React, {FC, useEffect, useState} from 'react';
import {Badge, Button, Card, Empty, Popconfirm, Space, Table, Typography} from "antd";
import {NumericFormat} from "react-number-format";

import {i18n} from "@app/translations/i18n.config";
import {RQContent} from "@app/components/rq_content";
import {ReceiptModal} from "@app/components/modals/receipt_modal";
import {Tools} from "@app/utils/tools";

import {ReactComponent as PlusIcon} from "@app/assets/icons/plus.svg";
import {ReactComponent as SearchIcon} from "@app/assets/icons/search.svg";
import {ReactComponent as DeleteIcon} from "@app/assets/icons/delete.svg";
import {ReactComponent as EditIcon} from "@app/assets/icons/edit.svg";

interface ReceiptsTableProps {
  onChange: (receipts: any) => void;
  data: Array<any>;
  isDataLoading?: boolean;
  isActionsAllowed?: boolean;
  totalTax?: number;
  totalAmount?: number;
  totalTaxedAmount?: number;
}

export const ReceiptsTable: FC<ReceiptsTableProps> = (
  {
    onChange,
    data,
    isDataLoading,
    isActionsAllowed,
    totalTax,
    totalAmount,
    totalTaxedAmount
  }
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editReceiptId, setEditReceiptId] = useState(null);

  const handleDelete = (item) => {
    const updatedInvoices = data.filter((invoice) => invoice !== item);
    onChange(updatedInvoices);
  };

  const columns = [
    {
      width: 5,
      render: (record) => data.indexOf(record) + 1,
    },
    {
      title: i18n.t('invoices.tableTitle.invoice'),
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.company'),
      dataIndex: 'company',
      key: 'company',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="default" text={i18n.t('invoices.tableTitle.amount')}/>,
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="warning" text={i18n.t('invoices.tableTitle.tax')}/>,
      dataIndex: 'tax',
      key: 'tax',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="success" text={i18n.t('invoices.tableTitle.totalAmount')}/>,
      dataIndex: 'taxedAmount',
      key: 'taxedAmount',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.purchaseDescription'),
      dataIndex: 'description',
      key: 'description',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.clause'),
      dataIndex: 'type',
      key: 'type',
      render: (text) => text ? text : '---',
    },
    {
      width: 70,
      render: (record) =>
        isActionsAllowed && (
          <Space size={'large'} className={'d-flex justify-content-end'}>
            {record?.id && (
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                onClick={() => {
                  setEditReceiptId(record?.id)
                  setIsModalOpen(true)
                }}
                icon={<EditIcon/>}
              />
            )}
            <Popconfirm
              title={
                <>
                  <Typography.Title level={2}>{i18n.t('popconfirm.deleteInvoiceTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteInvoiceDescription')}</p>
                </>
              }
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'top'}
              onConfirm={() => handleDelete(record)}
              okButtonProps={{size: 'large', type: 'ghost'}}
              cancelButtonProps={{size: 'large', type: 'text'}}
            >
              <Button type={'dashed'} size={'small'} className={'collapsed'} icon={<DeleteIcon width={20} height={20}/>}/>
            </Popconfirm>
          </Space>
        )
    }
  ];

  const submitReceiptHandler = (receipt, isEdit) => {
    const updatedData = isEdit
      ? data.map(item => item.id === receipt.id ? receipt : item)
      : [...data, receipt];

    onChange(updatedData);
  }

  useEffect(() => {
    if (!isModalOpen) {
      setEditReceiptId(null)
    }
  }, [isModalOpen]);


  return (
    <Card
      bordered={false}
      title={
        <Space size={null} className={'w-100 justify-content-between'}>
          <Typography.Title level={3}>
            {i18n.t('invoices.title')}
          </Typography.Title>
          {Tools.isPresent(data) && (
            <Space>
              <Badge
                className={'inline default'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={totalAmount.toFixed(2)}
                    thousandSeparator=","/>
                }
                text={i18n.t('invoices.tableTitle.amount')}/>
              <Badge
                className={'inline warning'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={totalTax.toFixed(2)}
                    thousandSeparator=","/>
                }
                showZero
                text={i18n.t('invoices.tableTitle.tax')}/>
              <Badge
                className={'inline success'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={totalTaxedAmount.toFixed(2)}
                    thousandSeparator=","/>
                }
                text={i18n.t('invoices.tableTitle.totalAmount')}/>
            </Space>
          )}
          {
            isActionsAllowed ? (
              <Button
                icon={<PlusIcon/>}
                size={'small'}
                type={'default'}
                onClick={() => setIsModalOpen(true)}
              >
                {i18n.t('invoices.new')}
              </Button>
            ) : (
              <span> </span>
            )
          }
        </Space>
      }
    >
      {Tools.isBlank(data) ? (
        <Space className={'w-100 justify-content-center p-5'}>
          <Empty
            className={'light-grey-bg p-5'}
            image={<SearchIcon/>}
            description={
              isActionsAllowed && (
                <>
                  <p>{i18n.t('invoices.emptyText')}</p>
                  <Button
                    className={'m-auto'}
                    icon={<PlusIcon/>}
                    size={'small'}
                    type={'default'}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {i18n.t('invoices.new')}
                  </Button>
                </>
              )
            }
          />
        </Space>
      ) : (
        <RQContent status={"success"}>
          <Table
            loading={isDataLoading}
            pagination={{size: 'small', hideOnSinglePage: true, defaultPageSize: 5}}
            dataSource={data}
            columns={columns}
          />
        </RQContent>
      )}
      <ReceiptModal
        onReceiptSubmitted={submitReceiptHandler}
        open={isModalOpen}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
        receiptValues={editReceiptId ? data.find((r) => r?.id === editReceiptId) : null}
      />
    </Card>
  );
};
