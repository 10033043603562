import { UserRolesEnum } from '@app/types';
import { RequestStatuses } from '@app/types/statuses';

export const isEditRequestActionsAllowed = (
  userRoles: Array<string>,
  status: RequestStatuses,
  isOwner: boolean,
): boolean => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);

  switch (status) {
    case RequestStatuses.Draft:
    case RequestStatuses.ModifyRequest:
      return isUser || isOwner || isAdmin;
    case RequestStatuses.ReturnedForVerification:
      return isAdmin;
    default:
      return false;
  }
};

export const isAddCommentActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);

  return isUser || isAdmin || isAuditor;
};
