import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Col, Row, Space, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';

import {i18n} from '@app/translations/i18n.config';
import {EmployeeTable} from '@app/components/tables/employee_table';
import {ReceiptsTable} from '@app/components/tables/receipts_table';
import {ActionButton} from '@app/components/action_button';
import {Comments} from '@app/components/comments';

import {Attachments} from '@app/components/attachments';
import {useMutation, useQuery} from 'react-query';
import {getRefundRequest, updateRefundRequest} from '@app/api';
import {RQContent} from '@app/components/rq_content';
import {toast} from 'react-toastify';
import {RequestStatus} from '@app/components/request_status';
import {useAuth} from '@app/auth/auth-context';
import {isEditRequestActionsAllowed} from '@app/utils/permissions/permissions';
import {Tools} from '@app/utils/tools';

export const Request = () => {
  const {id} = useParams();
  const {userRoles, user} = useAuth();

  const [isAttachmentsEmpty, setIsAttachmentsEmpty] = useState(true);

  const {
    status: requestStatus,
    data: requestData,
    refetch: refetchRequestData,
  } = useQuery({
    queryKey: ['refund_request'],
    queryFn: () => getRefundRequest(id),
  });

  const {mutate: updateRequestMutate, isLoading: isUpdateLoading} = useMutation({
    mutationFn: (data: any) => updateRefundRequest(id, data),
    onSuccess: async () => {
      refetchRequestData();
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    },
  });

  const receiptsChangeHandler = (receipts) => {
    updateRequestMutate({data: {receipts}});
  };

  return (
    <Content className={'sub-content-white mx-2 px-4 py-2'}>
      <RQContent status={[requestStatus]}>
        <>
          <Space className={'mb-4 w-100 justify-content-between sticky-top z-3 py-2 bg-white'}>
            <Space size={'large'}>
              <Typography.Title level={1}>
                {i18n.t('request.title')} {requestData?.requestId}
              </Typography.Title>
              <RequestStatus status={requestData?.status?.name}/>
            </Space>
            <ActionButton
              request={requestData}
              disableSubmit={
                !requestData?.request?.receipts || requestData?.request?.receipts?.length < 1 || isAttachmentsEmpty
              }
            />
          </Space>
          <Row gutter={16}>
            <Col span={18} className={'d-flex vstack align-items-start'}>
              <Space direction={'vertical'} size={'middle'} className={'w-100 light-grey-bg border-radius-large p-2'}>
                <EmployeeTable/>

                <ReceiptsTable
                  //TODO change to save all data
                  onChange={receiptsChangeHandler}
                  isActionsAllowed={isEditRequestActionsAllowed(
                    userRoles,
                    requestData?.status.name,
                    requestData?.creatorId === user.sub,
                  )}
                  isDataLoading={isUpdateLoading}
                  totalAmount={requestData?.request?.totalAmount}
                  totalTax={requestData?.request?.totalTax}
                  totalTaxedAmount={requestData?.request?.totalTaxedAmount}
                  data={Tools.isPresent(requestData?.request?.receipts) ? requestData?.request?.receipts : []}
                />
              </Space>
              <Attachments
                handleAttachmentsStatus={setIsAttachmentsEmpty}
                requestId={id}
                isActionsAllowed={isEditRequestActionsAllowed(
                  userRoles,
                  requestData?.status.name,
                  requestData?.creatorId === user.sub,
                )}
              />
            </Col>
            <Col span={6}>
              <Comments
                requestId={id}
                requestCreatorId={requestData?.creatorId}
                requestStatus={requestData?.status.name}
              />
            </Col>
          </Row>
        </>
      </RQContent>
    </Content>
  );
};
