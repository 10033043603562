import React, { FC, useRef } from 'react';
import { Modal, Typography } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { useQuery } from 'react-query';

import { getTemplate } from '@app/api';
import { DynamicForm } from '@app/components/dynamic_form';
import { RQContent } from '@app/components/rq_content';
import { i18n } from '@app/translations/i18n.config';
import { Tools } from '@app/utils/tools';

interface InvoiceModalProps extends ModalProps {
  onReceiptSubmitted?: (data: any, isEdit: boolean) => void;
  receiptValues?: any;
}

export const ReceiptModal: FC<InvoiceModalProps> = ({ onReceiptSubmitted, receiptValues, ...otherProps }) => {
  const formRef = useRef(null);

  const { status, data } = useQuery({
    queryKey: ['template'],
    queryFn: () => getTemplate(),
  });

  const handleCancel = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
    if (otherProps.onCancel) {
      otherProps.onCancel(null);
    }
  };

  return (
    <Modal
      {...otherProps}
      width={450}
      onCancel={handleCancel}
      title={
        <Typography.Title level={3}>
          {receiptValues ? i18n.t('invoices.edit') : i18n.t('invoices.new')}
        </Typography.Title>
      }
    >
      <RQContent status={status}>
        <DynamicForm
          ref={formRef}
          key={data?.id}
          onSubmit={(receipt) => onReceiptSubmitted(receipt, Tools.isPresent(receiptValues))}
          template={data?.template?.properties?.receipts?.items}
          hideModal={() => otherProps.onCancel(null)}
          receiptValues={receiptValues}
        />
      </RQContent>
    </Modal>
  );
};
