import React, { FC, useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { RQContent } from '@app/components/rq_content';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';

import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { RequestStatus } from '@app/components/request_status';
import { RequestStatuses } from '@app/types/statuses';
import { deleteRefundRequest, getRefundRequests, submitRefundRequest } from '@app/api';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { toast } from 'react-toastify';
import { useAuth } from '@app/auth/auth-context';
import { RequestTransitionCommands, UserRolesEnum } from '@app/types';
import { Tools } from "@app/utils/tools";

interface RequestTableProps {
  isDrafts?: boolean;
  setDrafts?: (v: boolean) => void;
}

export const RequestTable: FC<RequestTableProps> = ({ isDrafts, setDrafts }) => {
  const { userRoles } = useAuth();

  const [draftRequests, setDraftRequests] = useState(null);
  const [requests, setRequests] = useState(null);

  const { status, refetch } = useQuery({
    queryKey: ['refund_requests'],
    queryFn: () => getRefundRequests(),
    onSuccess: (data) => {
      setDraftRequests(data.filter((request) => request.status.name === RequestStatuses.Draft));
      setRequests(data.filter((request) => request.status.name !== RequestStatuses.Draft));
    },
  });

  const { mutate: deleteRequestMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: (data: string) => deleteRefundRequest(data),
    onSuccess: async () => {
      refetch();
      toast.success('Request was successfully deleted');
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    },
  });

  const { mutate: startRequestMutate, isLoading: isStartLoading } = useMutation({
    mutationFn: (requestId: string) => submitRefundRequest(requestId, RequestTransitionCommands.Submit),
    onSuccess: async () => {
      refetch();
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    },
  });

  useEffect(() => {
    Tools.isBlank(requests) ? setDrafts(true) : setDrafts(false);
  }, [requests]);

  const columns = [
    {
      title: i18n.t('request.tableTitle.requestId'),
      dataIndex: 'requestId',
      key: 'requestId',
    },
    {
      title: i18n.t('request.tableTitle.status'),
      render: (record) => <RequestStatus status={record?.status?.name} />,
    },
    {
      title: i18n.t('request.tableTitle.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => dayjs(record).format('MM/DD/YYYY'),
    },
    {
      title: i18n.t('request.tableTitle.clause'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.administration'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.fullAmount'),
      render: (record) => (record.request.receipts ? record.request.totalAmount.toFixed(2) : '---'),
    },
    {
      title: i18n.t('request.tableTitle.recipient'),
      dataIndex: 'recipientId',
      key: 'recipientId',
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {!isDrafts &&
            record?.status?.name === RequestStatuses.NotReceived &&
            userRoles.includes(UserRolesEnum.Admin) && (
              <Button type={'primary'} size={'small'} onClick={() => startRequestMutate(record.id)}>
                {i18n.t('buttons.startTask')}
              </Button>
            )}
        </Space>
      ),
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {isDrafts && (
            <Popconfirm
              title={
                <>
                  <Typography.Title level={2}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
                </>
              }
              className={'danger'}
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'right'}
              onConfirm={() => deleteRequestMutate(record.id)}
              okButtonProps={{ size: 'large', type: 'ghost' }}
              cancelButtonProps={{ size: 'large', type: 'text' }}
            >
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                icon={<DeleteIcon width={20} height={20} />}
              />
            </Popconfirm>
          )}
          <Link className={'d-flex align-items-center'} to={`/request/${record.id}`}>
            <EditIcon />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <RQContent status={status}>
      <Table
        loading={isDeleteLoading || isStartLoading}
        dataSource={isDrafts ? draftRequests : requests}
        pagination={{ size: 'small', hideOnSinglePage: true }}
        columns={columns}
      />
    </RQContent>
  );
};
