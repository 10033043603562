import React, { FC } from 'react';
import { Button, Popconfirm, Space, Typography } from 'antd';

import { i18n } from '@app/translations/i18n.config';
import { ReactComponent as BlockIcon } from '@app/assets/icons/block.svg';
import { useMutation, useQuery } from 'react-query';
import { deleteRefundRequest, getAllowedEvents, submitRefundRequest } from '@app/api';
import { RequestTransitionAction, RequestTransitionCommands } from '@app/types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RequestStatuses } from '@app/types/statuses';
import { RQContent } from '@app/components/rq_content';
import { useAuth } from '@app/auth/auth-context';

interface ActionButtonProps {
  request: any;
  disableSubmit?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({ request, disableSubmit }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { status, data: allowedActions } = useQuery({
    queryKey: ['allowed_events', request?.id],
    queryFn: () => getAllowedEvents(request?.id),
  });

  const { mutate: submitRequestMutate, isLoading: isSubmitLoading } = useMutation({
    mutationFn: (transitionAction: RequestTransitionCommands | string) => {
      return submitRefundRequest(request?.id, transitionAction);
    },
    onSuccess: async () => {
      navigate('/');
      toast.success('Request was successfully submitted');
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    },
  });

  const { mutate: deleteRequestMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: () => deleteRefundRequest(request?.id),
    onSuccess: async () => {
      navigate('/');
      toast.success('Request was successfully deleted');
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    },
  });

  const getButtonType = (transitionAction: RequestTransitionAction) => {
    switch (transitionAction.command) {
      case RequestTransitionCommands.Submit:
        return [RequestStatuses.Draft, RequestStatuses.ModifyRequest].includes(request?.status?.name) &&
          user.sub === request?.creatorId
          ? 'primary'
          : 'text';

      case RequestTransitionCommands.Cancel:
      case RequestTransitionCommands.Reject:
        return 'ghost';

      default:
        return 'default';
    }
  };

  return (
    <RQContent status={status} emptyElement={<></>}>
      <Space size={'middle'}>
        {allowedActions?.map((transitionAction: RequestTransitionAction) => (
          <Popconfirm
            title={
              <>
                <Typography.Title level={2} className={'text-capitalize'}>
                  {transitionAction.title}
                </Typography.Title>
                <p>
                  {transitionAction.confirmation ||
                    i18n.t('popconfirm.transitionsActionDescription', { event: transitionAction.command })}
                </p>
              </>
            }
            disabled={disableSubmit || isSubmitLoading || isDeleteLoading}
            onConfirm={() => submitRequestMutate(transitionAction.command)}
            icon={null}
            okText={i18n.t('popconfirm.yes')}
            cancelText={i18n.t('popconfirm.no')}
            placement={'bottomLeft'}
            okButtonProps={{ size: 'large', type: 'text' }}
            cancelButtonProps={{ size: 'large', type: 'ghost' }}
          >
            <Button
              disabled={disableSubmit || isSubmitLoading || isDeleteLoading}
              loading={isSubmitLoading}
              className={'text-capitalize'}
              type={getButtonType(transitionAction)}
            >
              {transitionAction.title}
            </Button>
          </Popconfirm>
        ))}
        {[RequestStatuses.Draft].includes(request?.status?.name) && (
          <Popconfirm
            title={
              <>
                <Typography.Title level={2}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
              </>
            }
            onConfirm={() => deleteRequestMutate()}
            icon={null}
            okText={i18n.t('popconfirm.yes')}
            cancelText={i18n.t('popconfirm.no')}
            placement={'bottomLeft'}
            okButtonProps={{ size: 'large', type: 'text' }}
            cancelButtonProps={{ size: 'large', type: 'ghost' }}
          >
            <Button
              disabled={isDeleteLoading || isSubmitLoading}
              loading={isDeleteLoading}
              icon={<BlockIcon />}
              type={'ghost'}
            >
              {i18n.t('buttons.delete')}
            </Button>
          </Popconfirm>
        )}
      </Space>
    </RQContent>
  );
};
